import { createSlice } from "@reduxjs/toolkit";
import {firstOutputTitle} from '../Data/Variables'
export const mySlice = createSlice({
  name: "modals",
  initialState:
  {
    outputTitle : firstOutputTitle,
    isMissingData : false,
    propsOutput : {
        "currencyKey" : {},
        "currencies" : {},
        "split" : []
    },
    hover : 0

  },
  reducers: 
  {
    setPropsOutput(state,action){state.propsOutput = action.payload;},
    setIsMissingData(state,action){state.isMissingData = action.payload;},
    setOutputTitle(state,action){state.outputTitle = action.payload;},
    setHover(state,action){state.hover = action.payload;},

  }
});

export const {setPropsOutput,setIsMissingData,setOutputTitle,setHover} = mySlice.actions;
export default mySlice.reducer;
