/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import Select from 'react-select'
import { useDispatch,useSelector} from "react-redux";

import { setModalsProps,setButtonOkLoad } from '../../../../redux/modals';
import { setPropsOutput,setHover,setOutputTitle } from '../../../../redux/customOutput';
import { setActionToDo } from '../../../../redux/portal';

import splitTable from '../../../../Images/SplitTable.png'
import { getMulValues, getValue, readToshort, shortToRead } from '../../../../Data/Variables';

const ModalSplit = ({tabs}) =>
{

    const { modalsProps,buttonOkLoad } = useSelector((state) => state.modals);
    const { theOutput,outputKind,tabMenuList,theCatalog } = useSelector((state) => state.portal);
    const { propsOutput,outputTitle,hover } = useSelector((state) => state.customOutput);
    let output = theOutput.split("_");
    let outputName = output.slice(1).join('_')
    const {OrigRenme} = useSelector((state) => state.globalVars);

    const dispatch = useDispatch();

    let optionLet = theOutput !== "" ? (
        !(outputName in OrigRenme[theCatalog])
        ?
            theCatalog !== undefined ? getValue(getMulValues(tabMenuList[theCatalog],["normal","tabs",outputName],{}),"columns",[]).map((x,index) => {return ({"value" : x, "label" : getValue(shortToRead,x,x)})}) : []
        :
            outputKind.split(" x ").slice(0, -2).map((x,index) => {return ({"value" : getValue(readToshort,x,x), "label" : getValue(shortToRead,x,x)})})
        )
        :{};

    return(<center>
        <div className={modalsProps["modalSplit"] ? "modal is-active" : "modal"} style={hover === 2 ? {"left" : "-60%","position" : "absolute"} : {"zIndex" : 30}}>
        {hover === 2 ?
            <div  style={{"position" : "absolute","top" : "3%","right" : "3%","zIndex" : 30,"width" : "33%"}}>
            <figure className="image is-5by4">
                <img src={splitTable} alt="split table img" />
            </figure>
        </div>
        :
            <></>
        }   
        <div className="modal-background"></div>
        <div className="modal-card" style={hover === 2 ? {"width" : "20%"} : {}}>
        <header className="modal-card-head">
            <p className="modal-card-title">Catalogue Settings</p>
            <button className="delete" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalSplit" : false}));dispatch(setPropsOutput({...propsOutput,"split" : outputTitle["split"]}))}}></button>
        </header>
        <section className="modal-card-body">
            <p className='is-size-6'>Split Table
                <a onClick={() => {hover !== 2 ? dispatch(setHover(2)) : dispatch(setHover(0))}} ><span className='icon has-text-info'><i className="fas fa-circle-info" /></span></a>
            </p><br/>
            <Select onChange={(dict) =>{dispatch(setPropsOutput({...propsOutput,"split" : dict.map((x) => x["value"])}));}} value={propsOutput["split"].map((x) => {return {"value" : getValue(readToshort,x,x),"label" : getValue(shortToRead,x,x)}})} options={optionLet} isMulti   

            menuPortalTarget={document.body} 
            styles={{ menuPortal: base => ({ ...base, zIndex: 60 }) }}/>
            
        </section>
        <footer className="modal-card-foot">
            <button className="button" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalSplit" : false}));dispatch(setPropsOutput({...propsOutput,"split" : outputTitle["split"]}))}}>Cancel</button>
            <button className={buttonOkLoad["GetOutput"] ? "button is-success is-loading" : "button is-success"} onClick={() => {dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : true})); dispatch(setOutputTitle({...outputTitle,"split" : propsOutput["split"]}));dispatch(setActionToDo("GetOutputSplited"))}}>Save changes</button>
        </footer>
        </div>
        </div>
        </center>);

}
export default ModalSplit;