import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "globalVars",
  initialState:
  {
     OrigRenme : {},
     TabObjects : [],
     defualttaboutput : {},
     tabtofolder : {},
     tabtofolderdraft : {},
     packstofolder : {},

  },
  reducers: 
  {
    //changeNameInAll(["Nathan","Standard Outputs Draft","SOD"]) 
    setOgRename(state,action) {state.OrigRenme = action.payload;},
    setTabObjects(state,action)
    {
      state.TabObjects = action.payload.tab_objects;
      state.defualttaboutput = action.payload.defualttaboutput;
      state.tabtofolder = action.payload.tabtofolder;
      state.tabtofolderdraft = action.payload.tabtofolderdraft;
      state.packstofolder = action.payload.packstofolder;
      state.ordertabs = action.payload.ordertabs

    },
  }
});

export const {setOgRename,setTabObjects} = mySlice.actions;
export default mySlice.reducer;
